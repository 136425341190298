import React, { useEffect, useState } from "react";
import ReactGlobe from "react-globe";
import {
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import OttHome from "../Pages/UserHome/UserHome";
import { liveStreamModal } from "../actions/index";
import { GlobalTimeline } from "../components";
import markers from "./markers";
import zIndex from "@material-ui/core/styles/zIndex";
import Overlay from "../components/CountryOverlay/countryOverlay";
import MiniDrawer from "../components/Sidebar/MaterialUISidebar"
const Home = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showLiveStream = useSelector((state) => state.liveStreamModal);
  const dispatch = useDispatch();

  const modalOverlayCSS = {
    zIndex: "10",
  };
  const [event, setEvent] = useState(null);
  const onClickMarker = function (marker, markerObject, event) {
    setEvent({
      type: "CLICK",
      marker,
      markerObjectID: markerObject.uuid,
      pointerEventPosition: { x: event.clientX, y: event.clientY },
    });
  };
  const modalBackCSS = {
    width: "2rem",
    position: "fixed",
    top: "2rem",
    left: "6rem",
    cursor: "pointer",
    filter: "invert(60%)",
  };

  const modalContentCSS = {
    width: "max-content",
    maxWidth: "70vw",
    margin: "auto",
    position: "absolute",
    left: "16rem",
    top: "5rem",
  };

  useEffect(() => dispatch(liveStreamModal(false)), []);

  const closeLiveStream = () => dispatch(liveStreamModal(false));
  return (
    <div style={showLiveStream ? { opacity: 0.9 } : {}}>
      <MiniDrawer />
      <div style={{ position: "relative" }}>
        <ReactGlobe
          globeCloudsTexture={null}
          height="100vh"
          markers={markers}
          onClickMarker={onClickMarker}
          options={{
            ambientLightColor: "white",
            ambientLightIntensity: 0.8,
            cameraAutoRotateSpeed: 0,
            cameraDistanceRadiusScale: 2,
            cameraMaxDistanceRadiusScale: 4,
            cameraMaxPolarAngle: Math.PI,
            cameraMinPolarAngle: 0,
            cameraRotateSpeed: 0.2,
            cameraZoomSpeed: 1,
            enableCameraAutoRotate: true,
            enableCameraRotate: true,
            enableCameraZoom: true,
            enableDefocus: true,
            enableGlobeGlow: true,
            enableMarkerGlow: true,
            enableMarkerTooltip: true,
            focusAnimationDuration: 1000,
            focusDistanceRadiusScale: 1.5,
            focusEasingFunction: ["Cubic", "Out"],
            globeCloudsOpacity: 0.3,
            globeGlowCoefficient: 0.1,
            globeGlowColor: "#d1d1d1",
            globeGlowPower: 3,
            globeGlowRadiusScale: 0.2,
            markerEnterAnimationDuration: 1000,
            markerEnterEasingFunction: ["Linear", "None"],
            markerExitAnimationDuration: 500,
            markerExitEasingFunction: ["Cubic", "Out"],
            markerGlowCoefficient: 0,
            markerGlowPower: 3,
            markerGlowRadiusScale: 2,
            markerOffsetRadiusScale: 0,
            markerRadiusScaleRange: [0.005, 0.02],
            markerRenderer: null,
            markerType: "bar",
            pointLightColor: "white",
            pointLightIntensity: 1,
            pointLightPositionRadiusScales: [-2, 1, -1],
            markerTooltipRenderer: (marker) => `${marker.city}`,
          }}
          width="100vw"
        />
        {showLiveStream ? (
          <Modal
            blockScrollOnMount={true}
            isOpen={showLiveStream}
            onClose={onClose}
          >
            <ModalOverlay style={{ modalOverlayCSS }} />

            <ModalContent textAlign="center" overflow="auto">
              <span style={modalBackCSS} onClick={closeLiveStream}>
                <img src="/icons/back-arrow.svg" />
              </span>

              <div style={modalContentCSS}>
                <OttHome {...props} isModal={true} />
              </div>
            </ModalContent>
          </Modal>
        ) : (
          ""
        )}
        ;
        {/* <GlobalTimeline /> */}
        {event ? <Overlay event={event} setEvent={setEvent} /> : false}
      </div>{" "}
    </div>
  );
};

export default Home;
