import * as React from "react";
import { useEffect } from "react";
import YouTube from "react-youtube";

const CountryMap = ({ cords,markers }) => {

  // Create a reference to the HTML element we want to put the map on
  const mapRef = React.useRef(null);
  // useEffect(() => console.log(cords), [cords]);

  /**
   * Create the map instance
   * While `useEffect` could also be used here, `useLayoutEffect` will render
   * the map sooner
   */
  React.useEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: 'cQ0sDefmoosRNwXr4yl3Ax5NNGLVGdno-Ku9Um3Q5mA',
    });
    const defaultLayers = platform.createDefaultLayers();
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: cords[0], lng: cords[1] },
      zoom: 4,
      pixelRatio: window.devicePixelRatio || 1,
    });

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

    const ui = H.ui.UI.createDefault(hMap, defaultLayers);

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener("resize", () => hMap.getViewPort().resize());

    let marker = new H.map.Marker({ lat: cords[0], lng: cords[1] },{
      
    });
    marker.setData(<img src="" />);
    
    
    var pngIcon = new H.map.Icon("https://img.icons8.com/office/30/000000/marker.png");      
    for(let i=0;i<markers.length;i++){
      var Finalmarker = new H.map.Marker({ lat: markers[i][0], lng: markers[i][1]},{
        icon:pngIcon
      })
      Finalmarker.setData('<a href="https://www.youtube.com/watch?v=cBNDRLp0Qhs&feature=emb_title">Watch Video</a>');
      hMap.addObject(Finalmarker);
      Finalmarker.addEventListener(
        "tap",
        function (evt) {
          // event target is the marker itself, group is a parent event target
          // for all objects that it contains
          var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData(),
          });
          // show info bubble
          ui.addBubble(bubble);
        },
        false
      );
      
    }
    // Add the marker to the map:
    console.log("markers from map", markers);

    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      hMap.dispose();
    };
  }, [mapRef, cords]); // This will run this hook every time this ref is updated

  return <div className="map" ref={mapRef} style={{ height: "500px" , width: '100%'}} />;
};

export default CountryMap;