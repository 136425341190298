import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { withFirebase } from "../../firebase/FirebaseContext";
const UserNavbarCourses = ({ firebase, history }) => {
  const [userDetails, setUserDetails] = useState("");
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        getUserProfileDetail();
        setUser(userAuth);
      }
    });
  }, [user]);
  const getUserProfileDetail = async () => {
    let snapShot = await firebase.getUserProfileDetails(user?.uid);
    const data = snapShot.data();
    if (data) {
      setUserDetails(data);
    }
  };

  const buttonToggle = (e) => {
    if (!e.target.classList.contains("opened"))
      e.target.classList.add("opened");
    else e.target.classList.remove("opened");
  };

  const focusHandler = (e) => {
    e.target.classList.add("active");
  };

  const blurHandler = (e) => {
    e.target.classList.remove("active");
  };

  return (
    <div className="header-container">
      <button type="button" className="menu" onClick={buttonToggle}>
        <svg width="30" height="30" viewBox="0 0 100 100">
          <path
            className="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path className="line line2" d="M 20,50 H 80" />
          <path
            className="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </button>

      <div className="exp-text">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/explified.svg`}
            className="exp-logo"
          />
        </Link>
        <Link to="/">Explified</Link>
      </div>
      <div className="cat">
        <Link to={`/category/science`}>Science</Link>
      </div>
      <div className="cat">
        <Link to={`/category/geography`}>Geography</Link>
      </div>
      {/* <div className="cat">
        <Link to={`/category/history`}>History</Link>
      </div>
      <div className="cat">
        <Link to={`/category/tech`}>Tech</Link>
      </div>
      <div className="cat">
        <Link to={`/category/travel`}>Travel</Link>
      </div> */}

      <div className="to-right">
        {user && (
          <div className="explified" style={{ padding: "0px 0px 0px 30px" }}>
            <Link to="/profile">
              <Avatar
                style={{ backgroundColor: "#23b5b5" }}
                //  src={userDetails.Url}
              />
            </Link>
            <button
              className="logout-button"
              style={{
                backgroundColor: "transparent",
                color: "#000",
                outline: "none",
                padding: "5px",
                margin: "0 5px",
                border: "none",
              }}
              onClick={() => {
                firebase.signOutUser();
                <Redirect push to="/login" />;
              }}
            >
              Logout
            </button>
            {/* <img src={`${process.env.PUBLIC_URL}/user.svg`} /> */}
          </div>
        )}
        {!user && (
          <Link
            className="logout-but"
            style={{
              backgroundColor: "transparent",
              color: "#000",
              outline: "none",
              padding: "5px",
              margin: "0 5px",
              border: "none",
            }}
            to="/login"
          >
            Login
          </Link>
        )}
        <span title="Explified">
          <a
            href="https://explified.com"
            style={{
              fontSize: "23px",
              padding: "5px",
              cursor: "pointer",
              color: "#000",
              marginLeft: "20px",
              textDecoration: "none",
            }}
          >
            &gt;
          </a>
        </span>
      </div>
    </div>
  );
};
export default withFirebase(UserNavbarCourses);
