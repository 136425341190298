import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Badge } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import "./UserProfileDetails.css";
const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    maxWidth: theme.spacing(30),
    maxHeight: theme.spacing(30),
  },
}));
const UserProfileDetails = ({ userDetails, setUserProfileImage, image }) => {
  const classes = useStyles();
  return (
    <div>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <span style={{ position: "relative" }}>
              <CameraAltIcon color="secondary" />
              <input
                className="custom-image-input"
                type="file"
                onChange={(e) => setUserProfileImage(e.target.files[0])}
              />
            </span>
          }
        >
          <Avatar
            src={image !== null ? URL.createObjectURL(image) : userDetails.Url}
            className={classes.large}
          />
        </Badge>
      </span>
    </div>
  );
};

export default UserProfileDetails;
