class HScroll {
  constructor(props) {
    this.startedLoading = props.startedLoading;
    this.videoCount = props.videoCount;
    this.slider = props.slider;
    this.mouseDown = props.mouseDown;
    this.startX = props.startX;
    this.scrollLeft = props.scrollLeft;
    this.x = props.x;
    this.scroll = props.scroll;
    this.maxScrollLeft = props.maxScrollLeft;
    this.limit = props.limit;
  }
}

export default HScroll;
