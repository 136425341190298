import UserSignIn from "./Pages/UserSignIn/UserSignIn";
import UserSignUp from "./Pages/UserSignUp/UserSignUp";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import OttHome from "./Pages/UserHome/UserHome";
import Home from "./Home/Home";

function Main() {
  return (
    <div>
      <BrowserRouter>
        <Route
          exact
          path="/"
          component={(props) => <UserSignIn {...props} />}
        />
        <Route
          path="/sign-up"
          component={(props) => <UserSignUp {...props} />}
        />
        <Route exact path="/home" component={(props) => <Home {...props} />} />
        <Route
          path="/livestream"
          component={(props) => <OttHome {...props} />}
        />
      </BrowserRouter>
    </div>
  );
}

export default Main;
