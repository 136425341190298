import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/firebase-analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVPnxv92jfanKyVm39sWwg6DArUbozGV4",
  authDomain: "explified-app.firebaseapp.com",
  databaseURL: "https://explified-app.firebaseio.com",
  projectId: "explified-app",
  storageBucket: "explified-app.appspot.com",
  messagingSenderId: "901696391731",
  appId: "1:901696391731:web:cc7fdab6bb43b23388b146",
  measurementId: "G-MKQECZX6Q7",
};
class FireBase {
  constructor() {
    this.app = firebase.initializeApp(firebaseConfig);
    this.analytics = this.app.analytics(); //firebase.analytics();
    this.auth = firebase.auth();
    this.user = firebase.auth().currentUser;

    this.db = this.app.firestore();
    this.storage = firebase.storage();

    this.usersRef = this.db.collection("users");
    this.videosRef = this.db.collection("Videos");
    this.categoriesRef = this.db.collection("Categories");
    this.subCategoriesRef = this.db.collection("Sub Categories");
    this.explifiedStoreRef = this.db.collection("Explified Store");
    this.batch = this.db.batch();
    this.GoogleProviderID = new firebase.auth.GoogleAuthProvider().providerId;
    this.PhoneProviderID = new firebase.auth.PhoneAuthProvider().providerId;
  }

  isLoggedIn = () => {
    return this.user ? true : false;
  };
  // componentDidMount = () => {
  //   console.log("lofffff", this.user);
  //   this.auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       this.setState = {
  //         isLoggedIn: true,
  //       };
  //       console.log("lofffff", user);
  //     } else {
  //       this.setState = {
  //         isLoggedIn: false,
  //       };
  //       console.log("hjhjhjh");
  //     }
  //   });
  // };

  // get token
  getToken = () => {
    return new Promise((resolve, reject) => {
      if (this.auth.currentUser) {
        this.auth.currentUser.getIdToken(true).then((token) => {
          resolve(token);
        });
      } else {
        resolve(null);
      }
    });
  };

  getUser = () => {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      }
    });
    return this.user;
  };

  captchaGeneraterforPhone = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-up-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // this.onSignInSubmit();
        },
      }
    );
  };

  onSignInSubmit = (phone) => {
    return new Promise((res, rej) => {
      const phoneNumber = phone;
      const appVerifier = window.recaptchaVerifier;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;

          res({ success: true, error: null });
        })
        .catch((error) => {
          // Error; SMS not sent
          // window.recaptchaVerifier.render().then((widgetId) => {
          //   window.recaptchaWidgetId = widgetId;
          // });
          console.log(error);
          rej({ success: false, error: error.message });
        });
    });
  };
  verifyOTP = (data, password, code) => {
    return new Promise((resolve, reject) => {
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          this.user = result.user;

          // updating display name
          this.auth.currentUser.updateProfile({
            displayName: data.fName + " " + data.lName,
          });

          // getting user credential
          var cred = firebase.auth.EmailAuthProvider.credential(
            data.email,
            password
          );
          // linking phone and email
          this.auth.currentUser
            .linkWithCredential(cred)
            .then((result) => {
              resolve({ success: true, error: null });
              return;
            })
            .catch((error) => {
              let user = firebase.auth().currentUser;
              user
                .delete()
                .then((result) => {
                  // User deleted.
                  this.user = null;
                })
                .catch((error) => {
                  console.log(error);
                });
              reject({ success: false, error: error.message });
              return;
            });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.log(error);
          reject({
            success: false,
            error:
              error.code === "auth/invalid-verification-code"
                ? "Please enter valid OTP"
                : error.message,
          });
        });
    });
  };

  createUser = async (data, password) => {
    try {
      const res = await this.auth.createUserWithEmailAndPassword(
        data.email,
        password
      );
      this.auth.currentUser.updateProfile({
        displayName: data.fName + " " + data.lName,
      });

      this.user = res.user;

      return { success: true, error: null };
      // console.log("creare user success", res);
    } catch (error) {
      var errorMessage = error.message;
      return { success: false, error: errorMessage };
      // // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // console.log("create user error", error);
    }
  };

  signInUser = async (email, password) => {
    try {
      const res = await this.auth.signInWithEmailAndPassword(email, password);
      // console.log("sign in  user", res);
      this.user = res.user;

      return { success: true, error: null };
    } catch (error) {
      // Handle Errors here.
      // var errorCode = error.code;
      var errorMessage = error.message;
      // console.log("sign in user error", error);
      return { success: false, error: errorMessage };
    }
  };

  // signInWithGoogle = async () => {
  //   var provider = new firebase.auth.GoogleAuthProvider();
  //   var success = false;
  //    var errorMsg = null;
  //   await this.auth
  //     .signInWithPopup(provider)
  //     .then(function (result) {
  //       // This gives you a Google Access Token. You can use it to access the Google API.
  //       var token = result.credential.accessToken;
  //       // The signed-in user info.
  //       // this.user = result.user;
  //       // console.log("hjhjh", result.user);
  //       success = true;
  //       errorMsg = null;
  //       // ...
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // Handle Errors here.
  //       // var errorCode = error.code;
  //       // var errorMessage = error.message;
  //       // The email of the user's account used.
  //       // var email = error.email;
  //       // The firebase.auth.AuthCredential type that was used.
  //       // var credential = error.credential;
  //       // ...
  //       errorMsg = error.message;
  //       success = false;
  //     });
  //     return {success, errorMsg};
  // };

  signOutUser = async () => {
    try {
      this.user = null;
      await this.auth.signOut();
      // console.log("Sign out successful");
    } catch (error) {
      // console.log("error signing out", error);
    }
  };

  getExplifiedStore = (id) => {
    return this.explifiedStoreRef.doc(id).get();
  };
  
  getRecords = () => {
    return this.videosRef.orderBy("timestamp", "desc").get();
  };

  getCategories = () => {
    return this.categoriesRef.orderBy("order", "asc").get();
  };

  getSubCategories = () => {
    return this.subCategoriesRef.orderBy("timestamp", "desc").get();
  };

  isVideoId = async (videoID) => {
    let isPresent = true;
    await this.videosRef
      .where("videoID", "==", videoID)
      .get()
      .then(function (querySnapshot) {
        isPresent = querySnapshot.empty;
        // querySnapshot.forEach(function (doc) {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        // });
        return;
      })
      .catch(function (error) {
        // console.log("Error getting documents: ", error);
      });
    return isPresent;
  };

  addRecord = async (record) => {
    await this.increaseVideoCount(record.categoryID);
    return this.videosRef.add({ ...record });
  };

  addCategory = (category) => {
    return this.categoriesRef.add({ ...category });
  };
  addSubCategory = (category) => {
    return this.subCategoriesRef.add({ ...category });
  };

  updateRecord = async (id, record) => {
    return this.videosRef.doc(id).update(record);
  };

  updateExplifiedStore = (id, record) => {
    return this.explifiedStoreRef.doc(id).update(record);
  };

  addFieldInAllDocument = async (newFields) => {
    var nycRef = await this.videosRef.get();
    nycRef.docs.forEach((queryResult) => {
      return this.db
        .runTransaction(function (transaction) {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(queryResult.ref).then(function (sfDoc) {
            if (!sfDoc.exists) {
              throw new Error("Document does not exist!");
            }
            transaction.update(queryResult.ref, newFields);
          });
        })
        .then(function () {
          // console.log("Transaction successfully committed!");
        })
        .catch(function (error) {
          // console.log("Transaction failed: ", error);
        });
    });
  };

  updateMultipleRecords = async (oldValue, newValue, property) => {
    let snapShot = await this.videosRef.where(property, "==", oldValue).get();
    snapShot.docs.forEach(async (doc) => {
      await this.updateRecord(doc.id, {
        ...doc.data(),
        [property]: newValue,
      });
    });
  };
  // increase video count by 1

  increaseVideoCount = async (categoryID) => {
    let cat = false;
    await this.categoriesRef
      .doc(categoryID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          cat = true;
        }
      });
    var ref = null;
    if (cat) {
      ref = this.categoriesRef.doc(categoryID);
    } else {
      ref = this.subCategoriesRef.doc(categoryID);
    }
    return this.db
      .runTransaction(function (transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(ref).then(function (sfDoc) {
          if (!sfDoc.exists) {
            throw new Error("Document does not exist!");
          }

          // Add one count to the video count
          // Note: this could be done without a transaction
          //       by updating the population using FieldValue.increment()
          var newVideoCount = sfDoc.data().videoCount + 1;
          transaction.update(ref, {
            videoCount: newVideoCount,
          });
        });
      })
      .then(function () {
        // console.log("Transaction successfully committed!");
      })
      .catch(function (error) {
        // console.log("Transaction failed: ", error);
      });
  };

  updateCategory = (id, record) => {
    return this.categoriesRef.doc(id).update({ ...record });
  };
  updateSubCategory = (id, record) => {
    return this.subCategoriesRef.doc(id).update({ ...record });
  };

  deleteRecord = async (record) => {
    await this.decreaseVideoCount(record.categoryID);
    return this.videosRef.doc(record.id).delete();
  };
  deleteVideoRecord = (record) => {
    var delVidRef = this.storage.ref(
      `users/${record.UserID}/videos/${record.title}`
    );
    var delThumRef = this.storage.ref(
      `users/${record.UserID}/thumbnail/${record.title}`
    );
    this.videosRef
      .doc(record.id)
      .delete()
      .then(async () => {
        await this.decreaseVideoCount(record);
        delVidRef.delete();
        delThumRef.delete();
        return;
      })
      .catch(function (error) {
        console.log("error in deleting file", error);
        return;
      });
  };

  // decrease video count by 1
  decreaseVideoCount = async (categoryID) => {
    let cat = false;
    await this.categoriesRef
      .doc(categoryID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          cat = true;
        }
      });
    var ref = null;
    if (cat) {
      ref = this.categoriesRef.doc(categoryID);
    } else {
      ref = this.subCategoriesRef.doc(categoryID);
    }

    return this.db
      .runTransaction(function (transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(ref).then(function (sfDoc) {
          if (!sfDoc.exists) {
            throw new Error("Document does not exist!");
          }

          // Subtract one count to the video count
          // Note: this could be done without a transaction
          //       by updating the population using FieldValue.increment()
          var newVideoCount = sfDoc.data().videoCount - 1;
          transaction.update(ref, { videoCount: newVideoCount });
        });
      })
      .then(function () {
        // console.log("Transaction successfully committed!");
      })
      .catch(function (error) {
        // console.log("Transaction failed: ", error);
      });
  };

  deleteCategory = (id) => {
    return this.categoriesRef.doc(id).delete();
  };
  deleteSubCategory = (id) => {
    return this.videosRef.doc(id).delete();
  };

  fromSecondsToTimestamp = (seconds, nanoseconds = 0) => {
    if (!seconds) return firebase.firestore.Timestamp.now();
    const newTimestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);
    return newTimestamp;
  };

  resetPassword = async (email) => {
    return this.auth.sendPasswordResetEmail(email);
  };

  addUserRecord = (record) => {
    return this.usersRef.doc(this.user.uid).set({
      ...record,
      UserID: this.user.uid,
      Url: "",
      timestamp: firebase.firestore.Timestamp.now(),
    });
  };
  getUserProfileDetails = (id) => {
    return this.usersRef.doc(id).get();
  };
  getUserProfileImage = () => {
    return this.storage;
  };
  updateProfileDetails = (id, details) => {
    return this.usersRef.doc(id).update(details);
  };
  sendEmailVerification = () => {
    var user = this.getUser();
    try {
      if (!user.emailVerified) {
        user.sendEmailVerification();
        return { success: true, error: null };
      } else {
        // console.log("Already verified");
        return { verified: true };
      }
    } catch (error) {
      return { success: false, error: error.message };
    }
  };
}
export default FireBase;
