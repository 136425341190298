import React, {useState,useEffect} from "react";
import "./UserSignIn.css";
import { withFirebase } from "../../firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

const UserSignIn = ({ firebase, history }) => {
    
  const [signingIn, setSigningIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup", //redirect
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/home",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.GoogleProviderID,
      {
        provider: firebase.PhoneProviderID,
        defaultCountry: "IN",
        recaptchaParameters: {
          type: "image",
          size: "invisible",
          badge: "bottomRight",
        },
      },
    ],

    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: "https://explified.com/terms-of-service/",
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign("https://explified.com/privacy-policy/");
    },

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (res) => {
        const newRecord = {
          fName: res.additionalUserInfo.profile.given_name,
          lName: res.additionalUserInfo.profile.family_name,
          email: res.additionalUserInfo.profile.email,
          Url: res.additionalUserInfo.profile.picture,
          UserID: res.user.uid,
        };

        if (res.user.uid) {
          firebase.usersRef.doc(res.user.uid).set({
            ...newRecord,
            timestamp: firebase.fromSecondsToTimestamp(),
          });
          firebase.videoEditorRef.doc(res.user.uid).set({
            tier:0,
            timestamp: firebase.fromSecondsToTimestamp(),
          });
          // firebase.addUserRecord(newRecord);
        }
      },
      signInFailure: function (error) {
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        return setError(error.message);
      },
    },
  };
  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        history.push("/home");
      }
      //  else {
      //   history.push("/login");
      // }
    });
  }, []);
  useEffect(() => {
    if (!!email && !!password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  const handleLoginButton = async (e) => {
    e.preventDefault();
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      setPasswordError("");
      return setEmailError("Enter valid Email");
    }
    if (password.length < 6) {
      setEmailError("");
      setPasswordError("Password must be at least six characters");
      return;
    }
    setDisabled(true);
    setSigningIn(true);
    const res = await firebase.signInUser(email, password);
    const { success, error } = res;
    if (success) {
      return history.push("/home");
    } else {
      setPasswordError("");
      setEmailError("");
      setError(error);
      setSigningIn(false);
      setDisabled(false);
    }
  };

  return (
    <div className="signinContainer">
      <h1 className="heading">Explified</h1>
      <img
        style={{ height: "300px", width: "55%", marginLeft: "23%" }}
        src="https://lunchclub.com/static/media/cities-graphic.e00b93ed.svg"
      />
      <div className="inputfields">
        <input type="text" className="inputfield"  onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <input type="password" className="inputfield" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        <button onClick={handleLoginButton} className="submitButtons">Let's Get Started</button>
      </div>
      <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth}
              />
    </div>
  );
};

export default withFirebase(UserSignIn);
