import React from "react";
import MobileStoreButton from "react-mobile-store-button";
import "./Footer.css";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Footer() {
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.developersmobiapp.explified";
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <h4>Our Mission</h4>
            <ul className="list-unstyled">
              <li>
                <a href="http://explified.com">
                  Making it easier to explore and express thorugh videos
                </a>
              </li>
            </ul>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4>Legal</h4>
            <ul className="list-unstyled">
              <li>
                <a href="http://explified.com/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="http://explified.com/disclaimer">Disclaimer</a>
              </li>
              <li>
                <a href="http://explified.com/terms-of-service">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
          {/* Column3 */}
          <div className="col">
            <h4>About Us</h4>
            <ul className="list-unstyled">
              <li>
                <a href="http://explified.com/contact">Contact</a>
              </li>
              <li>
                <a href="http://explified.com/blog">Blog</a>
              </li>
              <li>
                <a href="http://explified.com/studio">Studio</a>
              </li>
            </ul>
          </div>
          {/* Column4 */}
          <div className="col">
            {/* store="ios" */}
            <div className="">
              <MobileStoreButton
                store="android"
                url={playStoreUrl}
                linkProps={{ title: "Download on Google Play Store" }}
              />
            </div>
            <div className="social-icons">
              <a href="http://youtube.com/c/explified" target="_blank">
                <FontAwesomeIcon title="Youtube" icon={faYoutube} />
              </a>
              <a href="http://facebook.com/explified" target="_blank">
                <FontAwesomeIcon title="Facebook" icon={faFacebook} />
              </a>
              <a href="http://instagram.com/explified" target="_blank">
                <FontAwesomeIcon title="Instagram" icon={faInstagram} />
              </a>
              <a href="http://linkedin.com/company/explified" target="_blank">
                <FontAwesomeIcon title="Linkedin" icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>
        {/* <hr /> */}
        {/* Footer Bottom */}
        {/* <div className="footer-bottom">
          <span>
            &copy; {new Date().getFullYear()} Explified | All rights reserved
          </span>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
