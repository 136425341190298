import { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import CountryMap from "./ReactMap2d";
import axios from "axios";
import "./countryOverlay.css";
import { withFirebase } from "../../firebase/FirebaseContext";
import YouTube from "react-youtube";
import { Scrollbars } from "react-custom-scrollbars";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles({
  IconButton: {
    marginRight: 30,
    padding: 10,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },
});
function Overlay({ event, setEvent, firebase }) {
  const classes = useStyles();
  const [searchBoxText, setSearchBoxText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [flag, setFlag] = useState(true);
  const [cords, setCords] = useState(event.marker.coordinates);
  const [countryMapRenderFlag, setCountryMapRenderFlag] = useState(true);
  const [records, setRecords] = useState([]);
  const [countryfilter, setCountryfilter] = useState([]);
  const [country, setCountry] = useState("");
  const [markers, setMarkers] = useState([
    [12, 77],
    [26, 91],
    [11, 78],
  ]);
  const [countryName, setCountryName] = useState(event.marker.city);
  const [mapToggle, setMapToggle] = useState(false);
  console.log("country", countryName);
  let showmap = "Show Map";
  if (mapToggle) {
    showmap = "Hide Map";
  }
  const renderCountryMap = () => {
    setCountryMapRenderFlag(false);
    return <CountryMap cords={cords} />;
  };
  const MarkersSetter = () => {
    let FinalMarkers = [[]];
    console.log(records);
    for (let i = 0; i < records.length; i++) {
      if (records[i][2].value == undefined || records[i][3].value == undefined)
        continue;
      FinalMarkers.push([records[i][2].value, records[i][3].value]);
    }
    console.log("FinalMarkers", FinalMarkers);
    setMarkers(FinalMarkers);
  };
  const getRecords = async (category, audio, text) => {
    try {
      setCountry(event.marker.city);

      let snapShot = await firebase.getRecords(category, audio, text);
      const data = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let arrTitle = [[]];

      for (let i = 0; i < data.length; i++) {
        arrTitle[[i]] = [
          { title: data[i].title },
          { country: data[i].country },
          { longitude: data[i].longitude },
          { latitude: data[i].latitude },
          { videoID: data[i].videoID },
        ];
      }

      let stringfyDataTitle = JSON.stringify(arrTitle);
      let parsedDataTitle = JSON.parse(stringfyDataTitle);
      setRecords(parsedDataTitle);
     
      return parsedDataTitle;
     
    } catch (err) {
      console.log(err.message);
    }
  };

  const displayRecords = () => {
    
    let country = [[]];
    console.log(records.length);
    for (let i = 0; i < records.length; i++) {
      console.log(typeof records[i][1].value);
      if (records[i][1].country === countryName) {
        console.log(records[i][4]);
        country.push(records[i][4]);
        console.log(records[i][0].value);
      }
    }
    setCountryfilter(country);
  };
  console.log("mrkers", countryfilter);
  useEffect(async () => {
    await getRecords();
    
    
    console.log("records", await getRecords());
   

    const API_KEY = "cQ0sDefmoosRNwXr4yl3Ax5NNGLVGdno-Ku9Um3Q5mA";
    axios
      .get(
        `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${searchBoxText}&apiKey=${API_KEY}`
      )
      .then((res) => {
        // console.log(res.data.items);
        setSuggestions(res.data.items);
      });
  }, [searchBoxText]);

  const changeSearchBoxText = async (i) => {
    await setSearchBoxText(suggestions[i].title);
    setFlag(false);

    const API_KEY = "cQ0sDefmoosRNwXr4yl3Ax5NNGLVGdno-Ku9Um3Q5mA";

    await axios
      .get(
        `https://geocode.search.hereapi.com/v1/geocode?q=${searchBoxText}&apiKey=${API_KEY}`
      )
      .then(async (res) => {
        // console.log(res.data.items[0]);
        await setCords([
          res.data.items[0].position.lat,
          res.data.items[0].position.lng,
        ]);
        setCountryMapRenderFlag(true);
      });

    setSuggestions([]);
  };

  const [viewport, setViewport] = useState({
    width: 1000,
    height: 1000,
    latitude: event.marker.coordinates[0],
    longitude: event.marker.coordinates[1],
    zoom: 4,
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.62)",
        position: "fixed",
        top: 0,
        left: 0,
        paddingInline: 100,
        paddingTop: 20,
        overflow: "auto",
      }}
    >
      <div
        style={{
          left: 100,
          color: "white",
          width: "100%",
        }}
      >
        <div
          id="header-overlay-country"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <div
            className={classes.IconButton}
            onClick={() => {
              //setEvent(null);
              window.location.reload(true);
            }}
          >
            <ArrowBackIcon />
          </div>

          <h1 style={{ margin: 0, textAlign: "center" }}>
            <b>{countryName}</b>
          </h1>
          <div style={{ display: "flex",height:"5%",marginLeft:"50%"}}>
            
            <input
              style={{ padding: "5px" }}
              type="text"
              placeholder="search"
            />
            <i
              class="fa fa-search fa-lg"
              aria-hidden="true"
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "5px",
               
              }}
            ></i>
          </div>
          <div style={{ marginLeft: "3%" }}>
            <button
              style={{
                backgroundColor: "#0065b3",
                padding: "6px",
                color: "white",
                marginRight: "10px",
                borderRadius: "10px",
              }}
              onClick={displayRecords}
            >
              Find Videos
            </button>
            <button
              style={{
                backgroundColor: "#0065b3",
                padding: "6px",
                color: "white",
                borderRadius: "10px",
              }}
              onClick={() => setMapToggle(!mapToggle)}
            >
              {showmap}
            </button>
          </div>
          <div></div>
        </div>
        <div style={{ display: "flex" }}>
          {mapToggle ? (
            <Paper
              style={{
                width: "75%",
                padding: 20,
                marginTop: 70,
                height: "70vh",
              }}
            >
              {/* {countryMapRenderFlag && renderCountryMap()} */}
              <CountryMap cords={cords} markers={markers} />
            </Paper>
          ) : null}
         
          <div style={{ overflow: "hidden", width: "100%" }}>
            <div
              style={{
                backgroundColor: "white",
                color: "black",
                marginLeft: "5%",
                display:"flex",
              
                width: "100%",
                marginTop: "5%",
                backgroundColor: "transparent",
                color: "white",
                height: "80vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollBehavior: "smooth",
              }}
            >
              {countryfilter.map((data, key) => {
                console.log("country filter data", data);
                if (key !== 0) {
                  let source = `https://www.youtube.com/embed/${data.videoID}`;
                  const opts = {
                    height: "175",
                    width: "290",
                  };
                  return (
                    <div
                      style={{
                       
                        width:"200px",
                        margin: "0!important",
                        
                      }}
                    >
                      <div
                        key={key}
                        style={{
                         
                        }}
                      >
                        <div>
                          <YouTube videoId={data.videoID} opts={opts} />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withFirebase(Overlay);
