// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 1,
    city: "Aruba",
    coordinates: [12.5, -69.96666666],
    value: 0.001,
  },
  {
    id: 2,
    city: "Afghanistan",
    coordinates: [33, 65],
    value: 0.001,
  },
  {
    id: 3,
    city: "Angola",
    coordinates: [18.25, -63.16666666],
    value: 0.001,
  },
  {
    id: 3,
    city: "Albania",
    coordinates: [41, 20],
    value: 0.001,
  },
  {
    id: 4,
    city: "United Arab Emirates",
    coordinates: [24, 54],
    value: 0.001,
  },
  {
    id: 5,
    city: "Andorra",
    coordinates: [42.5, 1.5],
    value: 0.001,
  },
  {
    id: 6,
    city: "Argentina",
    coordinates: [-34, -64],
    value: 0.001,
  },
  {
    id: 7,
    city: "Armenia",
    coordinates: [40, 45],
    value: 0.001,
  },
  {
    id: 8,
    city: "American Samoa",
    coordinates: [-14.33333333, -170],
    value: 0.001,
  },
  {
    id: 9,
    city: "Antarctica",
    coordinates: [-90, 0],
    value: 0.001,
  },
  {
    id: 10,
    city: "French Southern and Antarctic Lands",
    coordinates: [-49.25, 69.167],
    value: 0.001,
  },
  {
    id: 11,
    city: "Afghanistan",
    coordinates: [33, 65],
    value: 0.001,
  },
  {
    id: 12,
    city: "Antigua and Barbuda",
    coordinates: [17.05, -61.8],
    value: 0.001,
  },
  {
    id: 13,
    city: "Australia",
    coordinates: [-27, 133],
    value: 0.001,
  },
  {
    id: 14,
    city: "Austria",
    coordinates: [47.33333333, 13.33333333],
    value: 0.001,
  },
  {
    id: 15,
    city: "Azerbaijann",
    coordinates: [40.5, 47.5],
    value: 0.001,
  },
  {
    id: 16,
    city: "Burundi",
    coordinates: [-3.5, 30],
    value: 0.001,
  },
  {
    id: 17,
    city: "Belgium",
    coordinates: [50.83333333, 4],
    value: 0.001,
  },
  {
    id: 18,
    city: "Benin",
    coordinates: [9.5, 2.25],
    value: 0.001,
  },
  {
    id: 19,
    city: "Burkina Faso",
    coordinates: [13, -2],
    value: 0.001,
  },
  {
    id: 20,
    city: "Bangladesh",
    coordinates: [24, 90],
    value: 0.001,
  },
  {
    id: 21,
    city: "Afghanistan",
    coordinates: [33, 65],
    value: 0.001,
  },
  {
    id: 22,
    city: "Bulgaria",
    coordinates: [43, 25],
    value: 0.001,
  },
  {
    id: 23,
    city: "Bahrain",
    coordinates: [26, 50.55],
    value: 0.001,
  },
  {
    id: 24,
    city: "Bahamas",
    coordinates: [24.25, -76],
    value: 0.001,
  },
  {
    id: 25,
    city: "Bosnia and Herzegovina",
    coordinates: [44, 18],
    value: 0.001,
  },
  {
    id: 26,
    city: "Saint Barth",
    coordinates: [18.5, -63.41666666],
    value: 0.001,
  },
  {
    id: 27,
    city: "Belarus",
    coordinates: [17.25, -88.75],
    value: 0.001,
  },
  {
    id: 28,
    city: "Bermuda",
    coordinates: [32.33333333, -64.75],
    value: 0.001,
  },
  {
    id: 29,
    city: "Bolivia",
    coordinates: [-17, -65],
    value: 0.001,
  },
  {
    id: 30,
    city: "Brazil",
    coordinates: [-10, -55],
    value: 0.001,
  },
  {
    id: 31,
    city: "Barbados",
    coordinates: [13.16666666, -59.53333333],
    value: 0.001,
  },
  {
    id: 32,
    city: "Brunei",
    coordinates: [4.5, 114.66666666],
    value: 0.001,
  },
  {
    id: 33,
    city: "Bhutan",
    coordinates: [27.5, 90.5],
    value: 0.001,
  },
  {
    id: 34,
    city: "Bouvet Islandanistan",
    coordinates: [-54.43333333, 3.4],
    value: 0.001,
  },
  {
    id: 35,
    city: "Botswana",
    coordinates: [-22, 24],
    value: 0.001,
  },
  {
    id: 36,
    city: "Central African Republic",
    coordinates: [7, 21],
    value: 0.001,
  },
  {
    id: 37,
    city: "Canada",
    coordinates: [60, -95],
    value: 0.001,
  },
  {
    id: 38,
    city: "Cocos (Keeling) Islands",
    coordinates: [-12.5, 96.83333333],
    value: 0.001,
  },
  {
    id: 39,
    city: "Switzerland",
    coordinates: [47, 8],
    value: 0.001,
  },
  {
    id: 40,
    city: "Afghanistan",
    coordinates: [33, 65],
    value: 0.001,
  },
  {
    id: 41,
    city: "Chile",
    coordinates: [-30, -71],
    value: 0.001,
  },
  {
    id: 42,
    city: "China",
    coordinates: [35, 105],
    value: 1,
  },
  {
    id: 42,
    city: "Ivory Coast",
    coordinates: [7.54, 5.5471],
    value: 0.001,
  },
  {
    id: 43,
    city: "Cameroon",
    coordinates: [6, 12],
    value: 0.001,
  },
  {
    id: 44,
    city: "DR Congo",
    coordinates: [0, 25],
    value: 0.001,
  },
  {
    id: 45,
    city: "Republic of the Congo",
    coordinates: [-1, 15],
    value: 0.001,
  },
  {
    id: 46,
    city: "Cook Islands",
    coordinates: [-21.23333333, -159.76666666],
    value: 0.001,
  },
  {
    id: 47,
    city: "Colombia",
    coordinates: [4, -72],
    value: 0.001,
  },
  {
    id: 48,
    city: "Comoros",
    coordinates: [-12.16666666, 44.25],
    value: 0.001,
  },
  {
    id: 49,
    city: "Cape Verde",
    coordinates: [16, -24],
    value: 0.001,
  },
  {
    id: 50,
    city: "Costa Ricastan",
    coordinates: [10, -84],
    value: 0.001,
  },
  {
    id: 51,
    city: "Cuba",
    coordinates: [21.5, -80],
    value: 0.001,
  },
  {
    id: 52,
    city: "Christmas Island",
    coordinates: [-10.5, 105.66666666],
    value: 0.001,
  },
  {
    id: 53,
    city: "Cura",
    coordinates: [12.116667, -68.933333],
    value: 0.001,
  },
  {
    id: 54,
    city: "Cayman Islands",
    coordinates: [19.5, -80.5],
    value: 0.001,
  },
  {
    id: 55,
    city: "Cyprus",
    coordinates: [35, 33],
    value: 0.001,
  },
  {
    id: 56,
    city: "Czech Republic",
    coordinates: [49.75, 15.5],
    value: 0.001,
  },
  {
    id: 57,
    city: "Germany",
    coordinates: [51, 9],
    value: 0.001,
  },
  {
    id: 58,
    city: "Djibouti",
    coordinates: [11.5, 43],
    value: 0.001,
  },
  {
    id: 59,
    city: "Dominica",
    coordinates: [15.41666666, -61.33333333],
    value: 0.001,
  },
  {
    id: 60,
    city: "Denmark",
    coordinates: [56, 10],
    value: 0.001,
  },
  {
    id: 61,
    city: "Dominican Republic",
    coordinates: [19, -70.66666666],
    value: 0.001,
  },
  {
    id: 62,
    city: "Algeria",
    coordinates: [28, 3],
    value: 0.001,
  },
  {
    id: 63,
    city: "Ecuador",
    coordinates: [-2, -77.5],
    value: 0.001,
  },
  {
    id: 64,
    city: "Egypt",
    coordinates: [27, 30],
    value: 0.001,
  },
  {
    id: 65,
    city: "Eritrea",
    coordinates: [15, 39],
    value: 0.001,
  },
  {
    id: 66,
    city: "Western Sahara",
    coordinates: [24.5, -13],
    value: 0.001,
  },
  {
    id: 67,
    city: "Afghanistan",
    coordinates: [33, 65],
    value: 0.001,
  },
  {
    id: 68,
    city: "Spain",
    coordinates: [40, -4],
    value: 0.001,
  },
  {
    id: 69,
    city: "Afghanistan",
    coordinates: [33, 65],
    value: 0.001,
  },
  {
    id: 70,
    city: "Estonia",
    coordinates: [59, 26],
    value: 0.001,
  },
  {
    id: 71,
    city: "Ethiopia",
    coordinates: [8, 38],
    value: 0.001,
  },
  {
    id: 72,
    city: "Finland",
    coordinates: [64, 26],
    value: 0.001,
  },
  {
    id: 73,
    city: "Fiji",
    coordinates: [-18, 175],
    value: 0.001,
  },
  {
    id: 74,
    city: "Falkland Islands",
    coordinates: [-51.75, -59],
    value: 0.001,
  },
  {
    id: 75,
    city: "France",
    coordinates: [46, 2],
    value: 0.001,
  },
  {
    id: 76,
    city: "Faroe Islands",
    coordinates: [62, -7],
    value: 0.001,
  },
  {
    id: 77,
    city: "Micronesia",
    coordinates: [6.91666666, 158.25],
    value: 0.001,
  },
  {
    id: 78,
    city: "Gabon",
    coordinates: [-1, 11.75],
    value: 0.001,
  },
  {
    id: 79,
    city: "United Kingdomnistan",
    coordinates: [54, -2],
    value: 0.001,
  },
  {
    id: 80,
    city: "Georgia",
    coordinates: [42, 43.5],
    value: 0.001,
  },
  {
    id: 81,
    city: "Guernsey",
    coordinates: [49.46666666, -2.58333333],
    value: 0.001,
  },
  {
    id: 82,
    city: "Ghana",
    coordinates: [8, -2],
    value: 0.001,
  },
  {
    id: 83,
    city: "Gibraltar",
    coordinates: [36.13333333, -5.35],
    value: 0.001,
  },
  {
    id: 84,
    city: "Guinea",
    coordinates: [11, -10],
    value: 0.001,
  },
  {
    id: 85,
    city: "Guadeloupe",
    coordinates: [16.25, -61.583333],
    value: 0.001,
  },
  {
    id: 86,
    city: "Gambia",
    coordinates: [13.46666666, -16.56666666],
    value: 0.001,
  },
  {
    id: 87,
    city: "Guinea-Bissau",
    coordinates: [12, -15],
    value: 0.001,
  },
  {
    id: 88,
    city: "Equatorial Guinea",
    coordinates: [2, 10],
    value: 0.001,
  },
  {
    id: 89,
    city: "Greece",
    coordinates: [39, 22],
    value: 0.001,
  },
  {
    id: 90,
    city: "Grenada",
    coordinates: [12.11666666, -61.66666666],
    value: 0.001,
  },
  {
    id: 91,
    city: "Greenland",
    coordinates: [72, -40],
    value: 0.001,
  },
  {
    id: 92,
    city: "Guatemala",
    coordinates: [15.5, -90.25],
    value: 0.001,
  },
  {
    id: 93,
    city: "French Guiana",
    coordinates: [4, -53],
    value: 0.001,
  },
  {
    id: 94,
    city: "Guam",
    coordinates: [13.46666666, 144.78333333],
    value: 0.001,
  },
  {
    id: 95,
    city: "Guyana",
    coordinates: [5, -59],
    value: 0.001,
  },
  {
    id: 96,
    city: "Hong Kong",
    coordinates: [22.267, 114.188],
    value: 0.001,
  },
  {
    id: 87,
    city: "Honduras",
    coordinates: [15, -86.5],
    value: 0.001,
  },
  {
    id: 98,
    city: "Croatia",
    coordinates: [45.16666666, 15.5],
    value: 0.001,
  },
  {
    id: 99,
    city: "Haiti",
    coordinates: [19, -72.41666666],
    value: 0.001,
  },
  {
    id: 100,
    city: "Hungary",
    coordinates: [47, 20],
    value: 0.001,
  },
  {
    id: 101,
    city: "Indonesia",
    coordinates: [-5, 120],
    value: 0.001,
  },
  {
    id: 102,
    city: "Isle of Man",
    coordinates: [54.25, -4.5],
    value: 0.001,
  },
  {
    id: 103,
    city: "India",
    coordinates: [20, 77],
    value: 0.001,
  },
  {
    id: 104,
    city: "British Indian Ocean Territory",
    coordinates: [-6, 71.5],
    value: 0.001,
  },
  {
    id: 105,
    city: "Ireland",
    coordinates: [53, -8],
    value: 0.001,
  },
  {
    id: 106,
    city: "Iran",
    coordinates: [32, 53],
    value: 0.001,
  },
  {
    id: 107,
    city: "Iraq",
    coordinates: [33, 44],
    value: 0.001,
  },
  {
    id: 107,
    city: "Iceland",
    coordinates: [65, -180],
    value: 0.001,
  },
  {
    id: 108,
    city: "Israel",
    coordinates: [31.47, 35.13],
    value: 0.001,
  },
  {
    id: 109,
    city: "Italy",
    coordinates: [42.83333333, 12.83333333],
    value: 0.001,
  },
  {
    id: 110,
    city: "Jamaica",
    coordinates: [18.25, -77.5],
    value: 0.001,
  },
  {
    id: 111,
    city: "Jersey",
    coordinates: [49.25, -2.16666666],
    value: 0.001,
  },
  {
    id: 112,
    city: "Jordan",
    coordinates: [36, 138],
    value: 0.001,
  },
  {
    id: 113,
    city: "Japan",
    coordinates: [47, 20],
    value: 0.001,
  },
  {
    id: 114,
    city: "Kazakhstan",
    coordinates: [48, 68],
    value: 0.001,
  },
  {
    id: 115,
    city: "Kenya",
    coordinates: [1, 38],
    value: 0.001,
  },
  {
    id: 116,
    city: "Kyrgyzstan",
    coordinates: [41, 75],
    value: 0.001,
  },
  {
    id: 117,
    city: "Cambodia",
    coordinates: [13, 105],
    value: 0.001,
  },
  {
    id: 118,
    city: "Kiribati",
    coordinates: [1.41666666, 173],
    value: 0.001,
  },
  {
    id: 119,
    city: "Saint Kitts and Nevis",
    coordinates: [17.33333333, -62.7],
    value: 0.001,
  },
  {
    id: 120,
    city: "South Korea",
    coordinates: [37, 127.5],
    value: 0.001,
  },
  {
    id: 121,
    city: "Kosovo",
    coordinates: [42.666667, 21.166667],
    value: 0.001,
  },
  {
    id: 122,
    city: "Kuwait",
    coordinates: [29.5, 45.75],
    value: 0.001,
  },
  {
    id: 123,
    city: "Laos",
    coordinates: [18, 105],
    value: 0.001,
  },
  {
    id: 124,
    city: "Lebanon",
    coordinates: [33.83333333, 35.83333333],
    value: 0.001,
  },
  {
    id: 125,
    city: "Liberia",
    coordinates: [6.5, -9.5],
    value: 0.001,
  },
  {
    id: 126,
    city: "Libya",
    coordinates: [25, 17],
    value: 0.001,
  },
  {
    id: 127,
    city: "Saint Lucia",
    coordinates: [13.88333333, -60.96666666],
    value: 0.001,
  },
  {
    id: 129,
    city: "Liechtenstein",
    coordinates: [47.26666666, 9.53333333],
    value: 0.001,
  },
  {
    id: 130,
    city: "Sri Lanka",
    coordinates: [7, 81],
    value: 0.001,
  },
  {
    id: 131,
    city: "Lesotho",
    coordinates: [-29.5, 28.5],
    value: 0.001,
  },
  {
    id: 132,
    city: "Lithuania",
    coordinates: [56, 24],
    value: 0.001,
  },
  {
    id: 133,
    city: "Luxembourg",
    coordinates: [49.75, 6.16666666],
    value: 0.001,
  },
  {
    id: 134,
    city: "Latvia",
    coordinates: [57, 25],
    value: 0.001,
  },
  {
    id: 135,
    city: "Macau",
    coordinates: [22.16666666, 113.55],
    value: 0.001,
  },
  {
    id: 136,
    city: "Saint Martin",
    coordinates: [18.08333333, -63.95],
    value: 0.001,
  },
  {
    id: 137,
    city: "Morocco",
    coordinates: [32, -5],
    value: 0.001,
  },
  {
    id: 138,
    city: "Monaco",
    coordinates: [43.73333333, 7.4],
    value: 0.001,
  },
  {
    id: 139,
    city: "Moldova",
    coordinates: [47, 29],
    value: 0.001,
  },
  {
    id: 140,
    city: "Hungary",
    coordinates: [47, 20],
    value: 0.001,
  },
  {
    id: 141,
    city: "Madagascar",
    coordinates: [-20, 47],
    value: 0.001,
  },
  {
    id: 142,
    city: "Maldives",
    coordinates: [3.25, 73],
    value: 0.001,
  },
  {
    id: 143,
    city: "Mexico",
    coordinates: [23, -102],
    value: 0.001,
  },
  {
    id: 144,
    city: "Marshall Islands",
    coordinates: [9, 168],
    value: 0.001,
  },
  {
    id: 145,
    city: "Macedonia",
    coordinates: [41.83333333, 22],
    value: 0.001,
  },
  {
    id: 146,
    city: "Mali",
    coordinates: [17, -4],
    value: 0.001,
  },
  {
    id: 147,
    city: "Malta",
    coordinates: [35.83333333, 14.58333333],
    value: 0.001,
  },
  {
    id: 148,
    city: "Myanmar",
    coordinates: [22, 98],
    value: 0.001,
  },
  {
    id: 149,
    city: "Montenegro",
    coordinates: [42.5, 19.3],
    value: 0.001,
  },
  {
    id: 150,
    city: "Mongolia",
    coordinates: [46, 105],
    value: 0.001,
  },
  {
    id: 151,
    city: "Northern Mariana Islands",
    coordinates: [15.2, 145.75],
    value: 0.001,
  },
  {
    id: 152,
    city: "Mozambique",
    coordinates: [-18.25, 35],
    value: 0.001,
  },
  {
    id: 153,
    city: "Mauritania",
    coordinates: [20, -12],
    value: 0.001,
  },
  {
    id: 154,
    city: "Montserrat",
    coordinates: [16.75, -62.2],
    value: 0.001,
  },
  {
    id: 155,
    city: "Martinique",
    coordinates: [14.666667, -61],
    value: 0.001,
  },
  {
    id: 156,
    city: "Mauritius",
    coordinates: [-20.28333333, 57.55],
    value: 0.001,
  },
  {
    id: 157,
    city: "Malawi",
    coordinates: [-13.5, 34],
    value: 0.001,
  },
  {
    id: 158,
    city: "Malaysia",
    coordinates: [2.5, 112.5],
    value: 0.001,
  },
  {
    id: 159,
    city: "Mayotte",
    coordinates: [-12.83333333, 45.16666666],
    value: 0.001,
  },
  {
    id: 160,
    city: "Namibia",
    coordinates: [-22, 17],
    value: 0.001,
  },
  {
    id: 161,
    city: "New Caledonia",
    coordinates: [-21.5, 165.5],
    value: 0.001,
  },
  {
    id: 162,
    city: "Niger",
    coordinates: [16, 8],
    value: 0.001,
  },
  {
    id: 163,
    city: "Norfolk Island",
    coordinates: [-29.03333333, 167.95],
    value: 0.001,
  },
  {
    id: 164,
    city: "Nigeria",
    coordinates: [10, 8],
    value: 0.001,
  },
  {
    id: 165,
    city: "Nicaragua",
    coordinates: [13, -85],
    value: 0.001,
  },
  {
    id: 166,
    city: "Niue",
    coordinates: [-19.03333333, -169.86666666],
    value: 0.001,
  },
  {
    id: 167,
    city: "Netherlands",
    coordinates: [52.5, 5.75],
    value: 0.001,
  },
  {
    id: 168,
    city: "Norway",
    coordinates: [62, 10],
    value: 0.001,
  },
  {
    id: 169,
    city: "Nepal",
    coordinates: [28, 84],
    value: 0.001,
  },
  {
    id: 170,
    city: "Nauru",
    coordinates: [-0.53333333, 166.91666666],
    value: 0.001,
  },
  {
    id: 171,
    city: "New Zealand",
    coordinates: [-41, 174],
    value: 0.001,
  },
  {
    id: 172,
    city: "Oman",
    coordinates: [21, 57],
    value: 0.001,
  },
  {
    id: 173,
    city: "Pakistan",
    coordinates: [30, 70],
    value: 0.001,
  },
  {
    id: 174,
    city: "Panama",
    coordinates: [9, -80],
    value: 0.001,
  },
  {
    id: 175,
    city: "Pitcairn Islands",
    coordinates: [-25.06666666, -130.1],
    value: 0.001,
  },
  {
    id: 176,
    city: "Peru",
    coordinates: [-10, -76],
    value: 0.001,
  },
  {
    id: 177,
    city: "Philippines",
    coordinates: [13, 122],
    value: 0.001,
  },
  {
    id: 178,
    city: "Palau",
    coordinates: [7.5, 134.5],
    value: 0.001,
  },
  {
    id: 179,
    city: "Papua New Guinea",
    coordinates: [-6, 147],
    value: 0.001,
  },
  {
    id: 180,
    city: "Poland",
    coordinates: [52, 20],
    value: 0.001,
  },
  {
    id: 181,
    city: "Puerto Rico",
    coordinates: [18.25, -66.5],
    value: 0.001,
  },
  {
    id: 182,
    city: "North Korea",
    coordinates: [40, 127],
    value: 0.001,
  },
  {
    id: 183,
    city: "Portugal",
    coordinates: [39.5, -8],
    value: 0.001,
  },
  {
    id: 184,
    city: "Paraguay",
    coordinates: [-23, -58],
    value: 0.001,
  },
  {
    id: 185,
    city: "Palestine",
    coordinates: [31.9, 35.2],
    value: 0.001,
  },
  {
    id: 186,
    city: "French Polynesia",
    coordinates: [-15, -140],
    value: 0.001,
  },
  {
    id: 187,
    city: "Qatar",
    coordinates: [25.5, 51.25],
    value: 0.001,
  },
  {
    id: 188,
    city: "Romania",
    coordinates: [46, 25],
    value: 0.001,
  },
  {
    id: 189,
    city: "Russia",
    coordinates: [60, 100],
    value: 0.001,
  },
  {
    id: 190,
    city: "Rwanda",
    coordinates: [-2, 30],
    value: 0.001,
  },
  {
    id: 191,
    city: "Saudi Arabia",
    coordinates: [25, 45],
    value: 0.001,
  },
  {
    id: 192,
    city: "Sudan",
    coordinates: [15, 30],
    value: 0.001,
  },
  {
    id: 193,
    city: "Senegal",
    coordinates: [14, -14],
    value: 0.001,
  },
  {
    id: 194,
    city: "Singapore",
    coordinates: [1.36666666, 103.8],
    value: 0.001,
  },
  {
    id: 195,
    city: "South Georgia",
    coordinates: [-54.5, -37],
    value: 0.001,
  },
  {
    id: 196,
    city: "Svalbard and Jan Mayen",
    coordinates: [78, 20],
    value: 0.001,
  },
  {
    id: 197,
    city: "Solomon Islands",
    coordinates: [-8, 159],
    value: 0.001,
  },
  {
    id: 198,
    city: "Sierra Leone",
    coordinates: [8.5, -11.5],
    value: 0.001,
  },
  {
    id: 199,
    city: "El Salvador",
    coordinates: [13.83333333, -88.91666666],
    value: 0.001,
  },
  {
    id: 200,
    city: "San Marino",
    coordinates: [43.76666666, 12.41666666],
    value: 0.001,
  },
  {
    id: 201,
    city: "Somalia",
    coordinates: [10, 49],
    value: 0.001,
  },
  {
    id: 202,
    city: "Saint Pierre and Miquelony",
    coordinates: [46.83333333, -56.33333333],
    value: 0.001,
  },
  {
    id: 203,
    city: "Serbia",
    coordinates: [44, 21],
    value: 0.001,
  },
  {
    id: 204,
    city: "South Sudan",
    coordinates: [7, 30],
    value: 0.001,
  },
  {
    id: 205,
    city: "Suriname",
    coordinates: [4, -56],
    value: 0.001,
  },
  {
    id: 206,
    city: "Slovakia",
    coordinates: [48.66666666, 19.5],
    value: 0.001,
  },
  {
    id: 207,
    city: "Slovenia",
    coordinates: [46.11666666, 14.81666666],
    value: 0.001,
  },
  {
    id: 208,
    city: "Sweden",
    coordinates: [62, 15],
    value: 0.001,
  },
  {
    id: 209,
    city: "Swaziland",
    coordinates: [-26.5, 31.5],
    value: 0.001,
  },
  {
    id: 210,
    city: "Sint Maarten",
    coordinates: [18.033333, -63.05],
    value: 0.001,
  },
  {
    id: 211,
    city: "Seychelles",
    coordinates: [-4.58333333, 55.66666666],
    value: 0.001,
  },
  {
    id: 212,
    city: "Syria",
    coordinates: [35, 38],
    value: 0.001,
  },
  {
    id: 213,
    city: "Turks and Caicos Islands",
    coordinates: [21.75, -71.58333333],
    value: 0.001,
  },
  {
    id: 214,
    city: "Chad",
    coordinates: [15, 19],
    value: 0.001,
  },
  {
    id: 215,
    city: "Togo",
    coordinates: [8, 1.16666666],
    value: 0.001,
  },
  {
    id: 216,
    city: "Thailand",
    coordinates: [15, 100],
    value: 0.001,
  },
  {
    id: 217,
    city: "Tajikistan",
    coordinates: [39, 71],
    value: 0.001,
  },
  {
    id: 218,
    city: "Tokelau",
    coordinates: [-9, -172],
    value: 0.001,
  },
  {
    id: 219,
    city: "Turkmenistan",
    coordinates: [40, 60],
    value: 0.001,
  },
  {
    id: 220,
    city: "Timor-Leste",
    coordinates: [-8.83333333, 125.91666666],
    value: 0.001,
  },
  {
    id: 221,
    city: "Tonga",
    coordinates: [-20, -175],
    value: 0.001,
  },
  {
    id: 222,
    city: "Trinidad and Tobago",
    coordinates: [11, -61],
    value: 0.001,
  },
  {
    id: 223,
    city: "Tunisia",
    coordinates: [34, 9],
    value: 0.001,
  },
  {
    id: 224,
    city: "Turkey",
    coordinates: [39, 35],
    value: 0.001,
  },
  {
    id: 225,
    city: "Tuvalu",
    coordinates: [-8, 178],
    value: 0.001,
  },
  {
    id: 226,
    city: "Taiwan",
    coordinates: [23.5, 121],
    value: 0.001,
  },
  {
    id: 227,
    city: "Tanzania",
    coordinates: [-6, 35],
    value: 0.001,
  },
  {
    id: 228,
    city: "Uganda",
    coordinates: [1, 32],
    value: 0.001,
  },
  {
    id: 229,
    city: "Ukraine",
    coordinates: [49, 32],
    value: 0.001,
  },
  {
    id: 230,
    city: "United States Minor Outlying Islands",
    coordinates: [19.2911437, 166.618332],
    value: 0.001,
  },
  {
    id: 231,
    city: "Uruguay",
    coordinates: [-33, -56],
    value: 0.001,
  },
  {
    id: 232,
    city: "United States",
    coordinates: [38, -97],
    value: 0.001,
  },
  {
    id: 233,
    city: "Uzbekistan",
    coordinates: [41, 64],
    value: 0.001,
  },
  {
    id: 234,
    city: "Vatican City",
    coordinates: [41.9, 12.45],
    value: 0.001,
  },
  {
    id: 235,
    city: "Saint Vincent and the Grenadines",
    coordinates: [13.25, -61.2],
    value: 0.001,
  },
  {
    id: 236,
    city: "Venezuela",
    coordinates: [8, -66],
    value: 0.001,
  },
  {
    id: 237,
    city: "British Virgin Island",
    coordinates: [18.431383, -64.62305],
    value: 0.001,
  },
  {
    id: 238,
    city: "United States Virgin Islands",
    coordinates: [18.35, -64.933333],
    value: 0.001,
  },
  {
    id: 239,
    city: "Vietnam",
    coordinates: [16.16666666, 107.83333333],
    value: 0.001,
  },
  {
    id: 240,
    city: "Vanuatu",
    coordinates: [-16, 167],
    value: 0.001,
  },
  {
    id: 241,
    city: "Wallis and Futuna",
    coordinates: [-13.3, -176.2],
    value: 0.001,
  },
  {
    id: 242,
    city: "Samoa",
    coordinates: [-13.58333333, -172.33333333],
    value: 0.001,
  },
  {
    id: 243,
    city: "Yemen",
    coordinates: [15, 48],
    value: 0.001,
  },
  {
    id: 244,
    city: "South Africa",
    coordinates: [-29, 24],
    value: 0.001,
  },
  {
    id: 245,
    city: "Zambia",
    coordinates: [-15, 30],
    value: 0.001,
  },
  {
    id: 246,
    city: "Zimbabwe",
    coordinates: [-20, 30],
    value: 0.001,
  },
];
