import React from "react";
import "./GlobeTimeline.css";

const GlobalTimeline = () => {
  return (
    <div>
      <div class="flex-parent">
        <div class="input-flex-container">
          <div class="input">
            <span data-year="" data-info="Before Christ"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="1AD"></span>
          </div>
          <div class="input active">
            <span data-year="" data-info="500 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="1000 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="1500 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="1700 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="1800 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="1900 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="2000 AD"></span>
          </div>
          <div class="input">
            <span data-year="" data-info="2020-Present"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GlobalTimeline;
