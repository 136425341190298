import React from "react";
import clsx from "clsx";
import "./MaterialUISidebar.css";
import Home from "../../Home/Home";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { liveStreamModal } from "../../actions/index";
import { withFirebase } from "../../firebase";
import firebase from "firebase";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#15171c",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      backgroundColor: "#15171c",
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#15171c",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#15171c",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#15171c",
    overflowX: "hidden",
    width: "70px",
    [theme.breakpoints.up("sm")]: {
      width: "60px",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#15171c",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
function ListItemLink(props) {
  return (
    <ListItem
      button
      component="a"
      style={{ textDecoration: "none" }}
      {...props}
    />
  );
}

function MiniDrawer({ history }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const logout = () => {
    console.log("hello");
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          // Sign-out successful.
          console.log("successful");
          window.location.href = "/";
        },
        function (error) {
          // An error happened.
        }
      );
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openLiveStreamModal = () => dispatch(liveStreamModal(true));

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/*<AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="white"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap color="black">
            Explified
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </div>

        <List>
          {/* {['Home', 'Watch', 'Booking', 'Visit','Events','Livestream','Videos'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon style={{ color: 'white' }}>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
             
            </ListItem>
            
          ))} */}
          <ListItemLink href="/">
            <ListItemText
              primary={<i class="fa fa-home fa-lg" aria-hidden="true"></i>}
            />
          </ListItemLink>

          <ListItem button onClick={openLiveStreamModal}>
            <ListItemText
              primary={<i class="fa fa-globe fa-lg" aria-hidden="true"></i>}
            />
          </ListItem>

          <ListItemLink>
            <Link to="/videocall">
              <ListItemText
                primary={<i class="fa fa-eye fa-lg" aria-hidden="true"></i>}
              />
            </Link>
          </ListItemLink>
          <ListItemLink>
            <button onClick={logout} style={{ backgroundColor: "transparent",color:"white",border:"none" }}>
              <i class="fa fa-sign-out fa-lg" aria-hidden="true"></i>
            </button>
          </ListItemLink>
        </List>
      </Drawer>
    </div>
  );
}
export default withFirebase(MiniDrawer);
