import React, { useState, useRef, useEffect } from "react";
import getVideoOfCat from "../../Utils/API/getVideoOfCat";
import getNextCategories from "../../Utils/API/getNextCategores";
import { withFirebase } from "../../firebase/FirebaseContext";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { CircularProgress } from "@material-ui/core";
import Hscroll from "../../Utils/HScroll/HScroll";
import { amountscrolled } from "../../Utils/VScroll/VScroll";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import UserNavbar from "../../components/UserNavbar/UserNavbar";
import "./UserHome.css";

const MyHome = ({ firebase, history, isModal }) => {
  // const [user, setUser] = useState(null);
  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged(async (userAuth) => {
  //     if (!userAuth) {
  //       history.push("/login");
  //     } else {
  //       history.push("/");
  //       setUser(userAuth);
  //     }
  //   });
  // }, [user]);
  
  // now is catgory page count that we keep
  // isLoading is for when the page loads for the first time
  // now_vid keeps track of which all categories have been loaded with track of pages of each video
  // cat_div is the main categories div loaction, we keep pushing new categories as we load
  // all_scroll keeps the HScroll object that is created with each category
  // all_cat_id keeps all the id to ensure cleaning of all the listeners that we have implemented

  let all_scroll = useRef({});
  let all_cat_id = useRef([]);
  let spring = useRef(false);
  let drag = useRef(false);
  let cancelRequest = useRef({});
  let cancel_ind = useRef(0);
  let all_video = useRef([]);
  let startY = useRef(0);

  let prev_id = useRef("");
  let par = useRef(null);
  let left_icon = useRef(null);
  let right_icon = useRef(null);

  const [isLoading, setLoadingState] = useState(true);
  // const [isFirstLoading, setFirstLoading] = useState(true);
  const [firstLoading, setfirstload] = useState(true);
  let lastCat = useRef("");
  let lastCatVideoCount = useRef(0);
  let lastvideoofCatID = useRef({});
  let lastvideoofCattimestamp = useRef({});
  let cat_div = useRef(null);
  let loadingMoreCat = useRef(false);
  let moreLeft = useRef(true);

  const flickityOptions = {
    initialIndex: 3,
    // cellAlign: "left",
    contain: true,
    selectedAttraction: 0.2,
    friction: 0.7,
    wrapAround: true,
    freeScroll: true,
    autoPlay: true,
    groupCells: "80%",
  };

  // for title
  const ellipsize = (str, limit) => {
    if (str.length > limit) {
      return `${str.slice(0, limit)}...`;
    }
    return str;
  };

  //    ------ video image load -------
  const loadImage = (src, className) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.src = src;
      img.className = className;
      img.onerror = reject;
    });
  };

  //    ----- each video div ---------
  const createVideoDiv = (video) => {
    return new Promise(async (resolve, reject) => {
      let outer_div = document.createElement("div");
      outer_div.className = "outer-video";

      let div = document.createElement("div");
      div.className = "video";
      div.id = video.id;
      div.onclick = () => {
        if (!drag.current)
          history.push({
            pathname: `/video/${video.title}`,
            state: {
              video: video,
              videoCount: all_scroll.current[`${video.categoryID}`].limit,
            },
          });
      };

      let card_title = document.createElement("p");
      card_title.className = "cardtitle";
      card_title.innerText = ellipsize(video.title, 28);

      let card_dur = document.createElement("p");
      card_dur.className = "card-duration";
      card_dur.innerText = video.duration;

      let shadow = document.createElement("div");
      shadow.className = "cardtitle-shadow";

      div.appendChild(shadow);
      div.appendChild(card_title);
      div.appendChild(card_dur);

      let tile;
      if (video.premiumVideo) tile = video.thumbnailURL;
      else
        tile = `https://img.youtube.com/vi/${video.videoID}/maxresdefault.jpg`;

      await loadImage(tile, "img-thumb")
        .then(async (res) => {
          div.appendChild(res);

          await loadImage(
            `${process.env.PUBLIC_URL}/icons/play-button.svg`,
            "video-btn"
          ).then((res) => {
            div.appendChild(res);
            outer_div.appendChild(div);

            let where_id = document.getElementById(`${video.categoryID}`);
            where_id.insertBefore(outer_div, where_id.lastChild);
            resolve();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  //    ---- each category div -----
  const createCategoryDiv = (category) => {
    let div = document.createElement("div");
    div.className = "category";

    let div_inner = document.createElement("div");
    div_inner.className = "child";
    div_inner.id = category.id;

    let slob = document.createElement("div");
    slob.className = "slob";

    div_inner.appendChild(slob);
    div.appendChild(div_inner);

    let name = document.createElement("div");
    name.className = "cat-name";

    let left = document.createElement("img");
    left.src = `${process.env.PUBLIC_URL}/icons/l2.svg`;
    left.className = "left";
    left.onclick = () => {
      all_scroll.current[`${category.id}`].slider.scrollBy({
        top: 0,
        left: -Math.min(
          290,
          all_scroll.current[`${category.id}`].slider.scrollLeft
        ),
        behavior: "smooth",
      });

      butToggle(category.id);
    };

    let right = document.createElement("img");
    right.className = "right";
    right.src = `${process.env.PUBLIC_URL}/icons/r2.svg`;
    right.onclick = () => {
      all_scroll.current[`${category.id}`].slider.scrollBy({
        top: 0,
        left: Math.min(
          290,
          Math.max(
            0,
            all_scroll.current[`${category.id}`].maxScrollLeft -
              200 -
              all_scroll.current[`${category.id}`].slider.scrollLeft
          )
        ),
        behavior: "smooth",
      });
      
      butToggle(category.id);
    };

    let actualname = document.createElement("div");
    actualname.innerText = category.category;
    actualname.appendChild(left);
    actualname.className = "pos-rel";
    name.appendChild(actualname);

    let more = document.createElement("div");
    more.className = "more pos-rel";
    let new_div = document.createElement("div");
    new_div.className = "this";
    new_div.innerText = "More";

    more.appendChild(new_div);
    more.appendChild(right);

    new_div.onclick = () => {
      history.push({
        pathname: `/category/${category.category}`,
        state: { category: category },
      });
    };
    name.appendChild(more);

    cat_div.current.appendChild(name);
    cat_div.current.appendChild(div);

    all_scroll.current[`${category.id}`].slider = cat_div.current.lastChild;
  };

  // ----scroll events

  // startdragging
  const startDragging = (e) => {
    let id;
    drag.current = false;

    if (e.target.closest(".child")) {
      id = e.target.closest(".child").id;
    } else {
      id = e.target.lastChild.id;
    }

    all_scroll.current[`${id}`].mouseDown = true;
    
    if(e.type === 'touchstart') {
      all_scroll.current[`${id}`].startX = e.changedTouches[e.changedTouches.length - 1].pageX - all_scroll.current[`${id}`].slider.offsetLeft;
      startY.current = e.changedTouches[e.changedTouches.length - 1].pageY - all_scroll.current[`${id}`].slider.offsetTop;
    }
    else 
      all_scroll.current[`${id}`].startX = e.pageX - all_scroll.current[`${id}`].slider.offsetLeft;
    
      all_scroll.current[`${id}`].scrollLeft =
      all_scroll.current[`${id}`].slider.scrollLeft;

    document.getElementById(`${id}`).classList.add("drag");

    if(e.type === 'mousedown') e.preventDefault();
  };

  //  stop dragging
  const stopDragging = (e) => {
    let id;

    if (e.target.closest(".child")) {
      id = e.target.closest(".child").id;
    } else {
      id = e.target.lastChild.id;
    }

    all_scroll.current[`${id}`].mouseDown = false;

    if(drag.current) {
      let doc = document.getElementById(`${id}`).classList;
      if(doc.contains('side-space')) doc.remove('side-space');
      else if(doc.contains('side-space-1')) doc.remove('side-space-1');
      spring.current = false;
      document.getElementById(`${id}`).classList.remove("drag");
    }
  };

  const Happy = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Happy");
      }, 0);
    });
  };

  const butToggle = (id) => {

    if(all_scroll.current[`${id}`].maxScrollLeft === 0) return;

    if(prev_id.current !== id) {
      par.current = document.getElementById(`${id}`).parentNode.previousSibling;
      left_icon.current = par.current.firstChild.lastChild;
      right_icon.current =  par.current.lastChild.lastChild;
      prev_id.current = id;
    }

    if(all_scroll.current[`${id}`].slider.scrollLeft === 0) {
      if(left_icon.current.classList.contains('unhide'))
        left_icon.current.classList.remove('unhide');
    }
    else {
      if(!left_icon.current.classList.contains('unhide'))
        left_icon.current.classList.add('unhide');
    }
    

    if(all_scroll.current[`${id}`].slider.scrollLeft + 150 <
        all_scroll.current[`${id}`].maxScrollLeft ) {

        if(!right_icon.current.classList.contains('unhide')) 
            right_icon.current.classList.add('unhide');
    }

    if(all_scroll.current[`${id}`].videoCount === 
        all_scroll.current[`${id}`].limit && 
        all_scroll.current[`${id}`].slider.scrollLeft + 220 >=
        all_scroll.current[`${id}`].maxScrollLeft ) {

        if(right_icon.current.classList.contains('unhide')) 
            right_icon.current.classList.remove('unhide');
    }
  }

  //   mouse motion
  const mouseMotion = async (e) => {
    drag.current = true;

    let id;
    if (e.target.closest(".child")) {
      id = e.target.closest(".child").id;
    } else {
      id = e.target.lastChild.id;
    }

    if (!all_scroll.current[`${id}`].mouseDown) {
      return;
    }

    if(e.type === 'touchmove'){
        all_scroll.current[`${id}`].x = e.changedTouches[e.changedTouches.length - 1].pageX - all_scroll.current[`${id}`].slider.offsetLeft;
        const y = e.changedTouches[e.changedTouches.length - 1].pageY - all_scroll.current[`${id}`].slider.offsetTop;
        const scrollY = y - startY.current; 
        const scrollX = all_scroll.current[`${id}`].x - all_scroll.current[`${id}`].startX;
        if(Math.abs(scrollX) <= 3*Math.abs(scrollY)) return;
    }
    else 
      all_scroll.current[`${id}`].x = e.pageX - all_scroll.current[`${id}`].slider.offsetLeft;
    
      all_scroll.current[`${id}`].scroll =
      all_scroll.current[`${id}`].x - all_scroll.current[`${id}`].startX;
    all_scroll.current[`${id}`].slider.scrollLeft =
      all_scroll.current[`${id}`].scrollLeft -
      all_scroll.current[`${id}`].scroll;

    if (
      all_scroll.current[`${id}`].slider.scrollLeft === 0 &&
      !spring.current &&
      all_scroll.current[`${id}`].scroll >= 0
    ) {
      spring.current = true;

      document.getElementById(`${id}`).classList.add("side-space");
    }

    if (
      all_scroll.current[`${id}`].slider.scrollLeft + 150 >=
        all_scroll.current[`${id}`].maxScrollLeft &&
      !spring.current &&
      all_scroll.current[`${id}`].scroll < 0
    ) {
      spring.current = true;

      document.getElementById(`${id}`).classList.add("side-space-1");
    }

    butToggle(id);

    if (
      !all_scroll.current[`${id}`].startedLoading &&
      all_scroll.current[`${id}`].slider.scrollLeft + 150 >=
        all_scroll.current[`${id}`].maxScrollLeft &&
      all_scroll.current[`${id}`].videoCount < all_scroll.current[`${id}`].limit
    ) {
      all_scroll.current[`${id}`].startedLoading = true;

      await getVideoOfCat(
        id,
        lastvideoofCatID.current[`${id}`],
        lastvideoofCattimestamp.current[`${id}`],
        cancelRequest.current,
        cancel_ind.current++
      )
        .then(async (res) => {
          res.forEach((video) => {
            lastvideoofCatID.current[`${video.categoryID}`] = video.videoID;
            lastvideoofCattimestamp.current[`${video.categoryID}`] =
              video.timestamp;
          });

          // shuffle Array
          shuffleArray(res);

          all_scroll.current[`${id}`].videoCount += res.length;

          await res.map(async (video) => {
            all_video.current.push(video);

            await createVideoDiv(video);

            await Happy().then((res) => {
              all_scroll.current[`${id}`].maxScrollLeft =
                all_scroll.current[`${id}`].slider.scrollWidth -
                all_scroll.current[`${id}`].slider.clientWidth;

              all_scroll.current[`${id}`].startedLoading = false;
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //  ---- all cat loaded

  const resizeEveryThing = () => {
    all_cat_id.current.forEach((id) => {
      all_scroll.current[`${id}`].maxScrollLeft =
        all_scroll.current[`${id}`].slider.scrollWidth -
        all_scroll.current[`${id}`].slider.clientWidth;
    });
  };

  // for loading more categories on vertical scrolling
  const loadMore = () => {
    let per = amountscrolled();
    if (per >= 96 && !loadingMoreCat.current && moreLeft.current) {
      loadingMoreCat.current = true;
      all_cat_loaded();
      setLoadingState(true);
    }
  };

  // for shuffling nay kind of response to create randomness
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  // category call
  const all_cat_loaded = () => {

    getNextCategories(
      lastCat.current, 
      lastCatVideoCount.current,
      cancelRequest.current,
      cancel_ind.current++
    )
    .then(async (res) => {
      if (res.length === 0) {
        moreLeft.current = false;
        return;
      }

      const result = res.filter((category) => {
        if (category.videoCount === 0) {
          moreLeft.current = false;
        }
        return category.videoCount !== 0;
      });

      result.forEach((category) => {
        lastCat.current = category.category;
        lastCatVideoCount.current = category.videoCount;
        lastvideoofCatID.current[`${category.id}`] = "";
        lastvideoofCattimestamp.current[`${category.id}`] = {};
      });

      shuffleArray(result);

      await Promise.all(
        result.map(async (category) => {
          all_cat_id.current.push(category.id);

          all_scroll.current[`${category.id}`] = new Hscroll({
            startedLoading: false,
            videoCount: Math.min(6, category.videoCount),
            limit: category.videoCount,
            slider: null,
            mouseDown: false,
            startX: 0,
            scrollLeft: 0,
            x: 0,
            scroll: 0,
            maxScrollLeft: 0,
          });

          createCategoryDiv(category);

          await getVideoOfCat(
            category.id,
            lastvideoofCatID.current[`${category.id}`],
            lastvideoofCattimestamp.current[`${category.id}`],
            cancelRequest.current,
            cancel_ind.current++
          )
            .then(async (res) => {
              res.forEach((video) => {
                all_video.current.push(video);
                lastvideoofCatID.current[`${video.categoryID}`] =
                  video.videoID;
                lastvideoofCattimestamp.current[`${video.categoryID}`] =
                  video.timestamp;
              });

              // shuffling video
              shuffleArray(res);

              let promises = res.map((video) => {
                return createVideoDiv(video);
              });

              await Promise.all(promises).then((res) => {
                // console.log("all videos loaded");
                let each_cat_div = all_scroll.current[`${category.id}`].slider;

                each_cat_div.addEventListener("mousemove", mouseMotion);
                each_cat_div.addEventListener("touchmove", mouseMotion);

                each_cat_div.addEventListener("mousedown", startDragging, false);
                each_cat_div.addEventListener("touchstart", startDragging, false);

                each_cat_div.addEventListener("mouseup", stopDragging, false);
                each_cat_div.addEventListener("touchend", stopDragging, false);

                each_cat_div.addEventListener("mouseleave", stopDragging, false);
                each_cat_div.addEventListener("touchcancel", stopDragging, false);
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
      )
      .then((res) => {

        window.addEventListener("resize", resizeEveryThing);
        window.addEventListener("scroll", loadMore, false);

        shuffleArray(all_video.current);

        if (firstLoading) setfirstload(false);

        let y = document.querySelectorAll(".right");
        let i = 0;

        if (isLoading) setLoadingState(false);
        loadingMoreCat.current = false;

        all_cat_id.current.map((id) => {

          all_scroll.current[`${id}`].maxScrollLeft =
            all_scroll.current[`${id}`].slider.scrollWidth -
            all_scroll.current[`${id}`].slider.clientWidth;

          // console.log(all_scroll.current[`${id}`].maxScrollLeft);
          
          if(all_scroll.current[`${id}`].maxScrollLeft !== 0) {
            if (!y[i].classList.contains("unhide"))
              y[i].classList.add("unhide");
          }

          i++;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    })
    .catch((error) => {
      console.log(error);
    });
  };

  let style;
  if (firstLoading) {
    style = {
      display: "none",
    };
  } else {
    style = {};
  }

  useEffect(() => {
    cat_div.current = document.querySelector(".categories");
    all_cat_loaded();
    let cleanup = all_cat_id;
    let cleanup_scroll = all_scroll;

    return () => {
      let i;
      for (i = 0; i < cancel_ind.current; i++) {
        let cancel = cancelRequest.current[`${i}`];
        cancel();
      }

      window.removeEventListener("resize", resizeEveryThing);
      window.removeEventListener("scroll", loadMore, false);
      cleanup.current.forEach((id) => {
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "mousemove",
          mouseMotion
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "touchmove",
          mouseMotion
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "mousedown",
          startDragging,
          false
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "touchstart",
          startDragging,
          false
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "mouseup",
          stopDragging,
          false
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "touchend",
          stopDragging,
          false
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "mouseleave",
          stopDragging,
          false
        );
        cleanup_scroll.current[`${id}`].slider.removeEventListener(
          "touchcancel",
          stopDragging,
          false
        );
      });
    };
  }, []);

  return (
    <React.Fragment>
      
      {!isLoading && (
        <Flickity
          className={"carousel"} // default ''
          elementType={"div"} // default 'div'
          options={flickityOptions} // takes flickity options {}
          static
        >
          {all_video.current.slice(0, 8).map((video, index) => {
            let tile = video.premiumVideo
              ? video.thumbnailURL
              : `https://img.youtube.com/vi/${video.videoID}/maxresdefault.jpg`;

            return (
              <Link
                key={index}
                to={{
                  pathname: `/video/${video.title}`,
                  state: {
                    video: video,
                    videoCount: all_scroll.current[`${video.categoryID}`].limit,
                  },
                }}
                style={{
                  marginLeft: "3%",
                  outline: "none",
                  textDecoration: "none",
                  border: "none",
                }}
              >
                <img src={tile} alt="image" className="carousel-img" />
                <div className="carousel-title">{video.title}</div>
                <div className="carousel-shadow-bottom"></div>
                <div className="carousel-shadow-top"></div>
              </Link>
            );
          })}
        </Flickity>
      )}
      <div className="user-home" style={isModal ? {backgroundColor: 'transparent'} : {}}>
        <div className="categories" style={style}></div>
        {isLoading && (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="secondary" />
          </span>
        )}
      </div>
      
    </React.Fragment>
  );
};

export default withFirebase(MyHome);

