import React from "react";
import { CreatorDrawer } from "../";
const CreatorLayout = ({ header, children, userDetails }) => {
  return (
    <div>
      <CreatorDrawer
        header={header}
        userDetails={userDetails}
        children={children}
      />
    </div>
  );
};

export default CreatorLayout;
