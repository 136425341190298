import axios from "axios";

const getVideoOfCat = (categoryid, lastvideoID, lasttimestamp, cancel, ind) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      baseURL: "https://backend-explified.web.app/",
      // baseURL: "http://localhost:5001/explified-app/us-central1/app/",
      url: "api/v2/ott/video/get",
      params: {
        limit: 6,
        id: categoryid,
        videoID: lastvideoID,
        timestamp: lasttimestamp,
      },
      cancelToken: axios.CancelToken((c) => (cancel[`${ind}`] = c)),
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        console.log(error);
      });
  });
};

export default getVideoOfCat;
