import React from "react";

import "./CourseItem.css";

const CourseItem = () => {
 
  return (
    <div className="course-item">

      <div className="course-item-left">
        <img
          src="https://cdn.pixabay.com/photo/2021/04/17/16/01/book-6186166_960_720.jpg"
          alt=""
        />
      </div>
      <div className="course-item-right">
        <div>Name</div>
        <div>description</div>
        <div className="course-item-right-bottom">
          <div>duration</div>
          <div>date</div>
        </div>
      </div>
    </div>
  );
};

export default CourseItem;
